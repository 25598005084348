import {
  CheckCircleFilled,
  CheckCircleOutlined,
  ClockCircleFilled,
  CustomerServiceOutlined,
  DeleteOutlined,
  MailFilled,
  MailOutlined,
  NodeIndexOutlined,
  PhoneFilled,
  PhoneOutlined,
  QuestionCircleFilled,
  RetweetOutlined,
  RollbackOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import { Alert, Button, Col, DatePicker, Divider, Form, notification, Popconfirm, Row, Skeleton, Space, Steps, Typography } from "antd";
import dayjs from "dayjs";
import * as React from "react";

import AppointmentTypePicker from "@/components/AppointmentTypePicker";
import EmployeePicker from "@/components/EmployeePicker";
import MarkdownTextInput from "@/components/MarkdownTextInput";
import { Modal } from "@/components/Modal";
import OptionalInformation from "@/components/OptionalInformation";
import PhoneNumber from "@/components/PhoneNumber";
import TimePicker from "@/components/TimePicker";
import { BACKEND_URL } from "@/constants";
import { isAppointmentTimeWithinTimeWindow } from "@/functions/appointment-util";
import formatAddress from "@/functions/format-address";
import formatDuration from "@/functions/format-duration";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";
import updateDayjsDate from "@/functions/update-dayjs-date";

import AttachmentInput, { filterAttachmentIdentifiers } from "../AttachmentInput";
import { AppointmentRemindMeSidebar } from "./AppointmentRemindMeSidebar";
import { AppointmentTimeWindowSidebar } from "./AppointmentTimeWindowSidebar";
import ProductsSummaryCard from "./ProductsSummaryCard";

function InformationL({ icon, value }: { icon: React.ReactElement; value?: React.ReactNode }) {
  if (!value) return null;

  return (
    <div>
      {React.cloneElement(icon, { style: { fontSize: "0.9rem" } })}
      <span style={{ fontSize: "0.85rem", marginLeft: 12 }}>{value}</span>
    </div>
  );
}

interface AppointmentModalProps {
  appointmentId: string;
  onClose: () => void;
  onInviteClick: () => void;
  visible: boolean;
}

export default function AppointmentModal({ appointmentId, onClose, onInviteClick, visible }: AppointmentModalProps) {
  const [formInstance] = Form.useForm();
  const valuesHaveChangesRef = React.useRef(false);

  const { data: appointmentData } = useQuery(AppointmentQuery, { variables: { appointmentId } });
  const appointment = appointmentData?.appointment;

  const [cancelAppointmentAsync, { loading: isCancelLoading }] = useMutation(CancelAppointmentMutation);
  const [scheduleAppointmentAsync, { loading: isScheduleLoading }] = useMutation(ScheduleAppointmentMutation);
  const [unscheduleAppointmentAsync, { loading: isUnscheduleLoading }] = useMutation(UnscheduleAppointmentMutation);
  const [confirmAppointmentAsync, { loading: isConfirmLoading }] = useMutation(ConfirmAppointmentMutation);
  const [updateAppointmentAsync, { loading: isUpdateLoading }] = useMutation(UpdateAppointmentMutation);

  const handleOnClose = () => {
    if (!valuesHaveChangesRef.current) {
      return onClose();
    }

    Modal.confirm({
      okType: "danger",
      title: "Niet al je wijzigingen zijn opgeslagen. Toch doorgaan?",
      onOk: () => onClose(),
    });
  };

  const handleOnCancelClick = async () => {
    try {
      await cancelAppointmentAsync({ variables: { appointmentId } });
      notification.success({ message: "Afspraak is geannuleerd" });
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  const handleOnScheduleClick = () => {
    formInstance.validateFields().then(async values => {
      if (!values.startTime || !values.endTime || !values.employeeId) {
        return notification.error({ message: "Geef een tijd aan en kies een medewerker" });
      }

      const dateProps = {
        year: values.date.year(),
        month: values.date.month(),
        date: values.date.date(),
      };

      const startTime = updateDayjsDate(values.startTime, dateProps);
      const endTime = updateDayjsDate(values.endTime, dateProps);

      // Ensure startTime < endTime..
      if (startTime >= endTime) {
        return notification.error({ message: "Starttijd moet eerder zijn dan eindtijd" });
      }

      try {
        const { date, ...updateableProps } = values;
        await scheduleAppointmentAsync({ variables: { ...updateableProps, appointmentId, startTime, endTime } });

        notification.success({ message: "Afspraak is ingepland" });
        valuesHaveChangesRef.current = false;
      } catch (error) {
        mapGraphQLErrorsToNotifications(error as ApolloError);
      }
    });
  };

  const handleOnUnscheduleClick = async () => {
    try {
      await unscheduleAppointmentAsync({ variables: { appointmentId } });
      notification.success({ message: "Afspraak is uitgeroosterd" });
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  const handleOnConfirmClick = async () => {
    try {
      await confirmAppointmentAsync({ variables: { appointmentId } });
      notification.success({ message: "Afspraak is bevestigd" });
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  const handleOnUpdateClick = async (values: Record<string, any>) => {
    if (formInstance.isFieldTouched("date") && (!values.startTime || !values.endTime)) {
      return notification.error({ message: "Datum kan niet los aangepast worden" });
    }

    if ((values.startTime && !values.endTime) || (!values.startTime && values.endTime)) {
      return notification.error({ message: "Geef een starttijd en eindtijd op" });
    }

    let startTime = values.startTime;
    let endTime = values.endTime;

    const isFieldTouched = formInstance.isFieldTouched;
    if (isFieldTouched("date") || isFieldTouched("startTime") || isFieldTouched("endTime")) {
      const dateProps = {
        year: values.date.year(),
        month: values.date.month(),
        date: values.date.date(),
      };

      startTime = updateDayjsDate(values.startTime, dateProps);
      endTime = updateDayjsDate(values.endTime, dateProps);
    }

    if (startTime >= endTime) {
      return notification.warning({ message: "Starttijd moet eerder zijn dan eindtijd" });
    }

    async function _executeUpdate() {
      try {
        await updateAppointmentAsync({
          variables: {
            appointmentId,
            appointmentTypeId: isFieldTouched("appointmentTypeId") ? values.appointmentTypeId : undefined,
            startTime: isFieldTouched("date") || isFieldTouched("startTime") ? startTime : undefined,
            endTime: isFieldTouched("date") || isFieldTouched("endTime") ? endTime : undefined,
            employeeId: isFieldTouched("employeeId") ? values.employeeId : undefined,
            onSiteComment: isFieldTouched("onSiteComment") ? values.onSiteComment : undefined,
            plannerComment: isFieldTouched("plannerComment") ? values.plannerComment : undefined,
            invoiceComment: isFieldTouched("invoiceComment") ? values.invoiceComment : undefined,
            attachmentIds: isFieldTouched("attachments") ? filterAttachmentIdentifiers(values.attachments) : undefined,
          },
        });

        notification.success({ message: "Afspraak is aangepast" });
        valuesHaveChangesRef.current = false;
      } catch (error) {
        mapGraphQLErrorsToNotifications(error as ApolloError);
      }
    }

    const shouldPromptForTimeWindow =
      appointment.displayTimeWindow && null !== appointment.timeWindowStart && null !== appointment.timeWindowEnd
        ? !isAppointmentTimeWithinTimeWindow(startTime, endTime, appointment.timeWindowStart, appointment.timeWindowEnd)
        : false;

    if (shouldPromptForTimeWindow) {
      Modal.confirm({
        title: "Opnieuw inplannen buiten tijdsvak",
        content:
          "Je gaat de afspraak opnieuw inplannen buiten het huidige tijdsvak. Als je doorgaat moet je de klant opnieuw een uitnodiging sturen. Doorgaan?",
        onOk: () => _executeUpdate(),
      });
    } else {
      _executeUpdate();
    }
  };

  return (
    <Modal
      centered
      onCancel={handleOnClose}
      footer={
        undefined !== appointment && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: appointment.status !== "STATUS_CANCELLED" ? "space-between" : "flex-end",
            }}
          >
            {appointment.status !== "STATUS_CANCELLED" && (
              <Popconfirm onConfirm={handleOnCancelClick} title="Afspraak echt annuleren?">
                <Button loading={isCancelLoading} danger>
                  <DeleteOutlined style={{ fontSize: 14 }} /> Annuleren
                </Button>
              </Popconfirm>
            )}
            <Button.Group size="middle">
              {appointment.status === "STATUS_REQUESTED" && (
                <Button loading={isScheduleLoading} icon={<CheckCircleOutlined />} onClick={handleOnScheduleClick}>
                  Inroosteren
                </Button>
              )}
              {(appointment.status === "STATUS_SCHEDULED" || appointment.status === "STATUS_CONFIRMED") && (
                <Button loading={isUnscheduleLoading} icon={<RollbackOutlined />} onClick={handleOnUnscheduleClick}>
                  Uitroosteren
                </Button>
              )}
              {appointment.status === "STATUS_SCHEDULED" && (
                <Button loading={isConfirmLoading} icon={<CheckCircleOutlined />} onClick={handleOnConfirmClick}>
                  Bevestigen
                </Button>
              )}
              {appointment.status === "STATUS_SCHEDULED" && appointment.appointmentType.hasEmailTemplate && (
                <Button disabled={null !== appointment.inviteSentOn} onClick={onInviteClick} icon={<MailOutlined />}>
                  {null === appointment.inviteSentOn
                    ? "Uitnodiging versturen"
                    : `Uitnodiging verstuurd op ${dayjs(appointment.inviteSentOn).format("DD-MM-YY")}`}
                </Button>
              )}
              <Button loading={isUpdateLoading} onClick={() => formInstance.submit()} icon={<RetweetOutlined />} type="primary">
                Wijzigingen opslaan
              </Button>
            </Button.Group>
          </div>
        )
      }
      styles={{
        body: {
          overflowY: "auto",
          maxHeight: window.innerHeight - 168,
        },
      }}
      title="Afspraak bekijken"
      open={visible}
      width="65%"
    >
      {undefined === appointment ? (
        <Skeleton />
      ) : (
        <Row gutter={32} justify="space-between">
          <Col span={12}>
            <Form
              form={formInstance}
              className="form-vertical-size-small"
              onFinish={handleOnUpdateClick}
              onValuesChange={() => {
                valuesHaveChangesRef.current = true;
              }}
              initialValues={{
                appointmentTypeId: appointment.appointmentType.id,
                employeeId: appointment.employee?.id || undefined,
                date: dayjs(appointment.startTime ?? appointment.prospectiveDate),
                startTime: appointment.startTime ? dayjs(appointment.startTime) : undefined,
                endTime: appointment.endTime ? dayjs(appointment.endTime) : undefined,
                plannerComment: appointment.plannerComment,
                onSiteComment: appointment.onSiteComment,
                invoiceComment: appointment.invoiceComment,
                attachments: (appointment.attachments ?? []).map(x => ({
                  uid: x.file.id,
                  name: x.file.name,
                  url: BACKEND_URL + "/file/" + x.file.id,
                })),
              }}
              layout="vertical"
            >
              <h4 style={{ marginBottom: 0 }}>
                {`${appointment.relation.afasCode ? `(${appointment.relation.afasCode})` : ""} ${appointment.relation.name}`.trim()}
              </h4>
              <div className="ant-steps-item-description" style={{ marginBottom: 4 }}>
                {appointment.relation.name !== appointment.location.name && <div>{appointment.location.name}</div>}
                <div>{formatAddress(appointment.location.address)}</div>
              </div>
              {appointment.relation.dealer && (
                <InformationL
                  icon={<NodeIndexOutlined />}
                  value={`Dealer-klant: (${appointment.relation.dealer.afasCode}) ${appointment.relation.dealer.name}`}
                />
              )}
              <Row justify="space-between" gutter={24} style={{ marginBottom: 10 }}>
                <Col span={12}>
                  <InformationL icon={<UserOutlined />} value={appointment.location.contactPerson} />
                  <InformationL icon={<MailOutlined />} value={appointment.location.primaryEmail ?? "-"} />
                  <InformationL
                    icon={<PhoneOutlined />}
                    value={appointment.location.phoneNumber ? <PhoneNumber>{appointment.location.phoneNumber}</PhoneNumber> : "-"}
                  />
                </Col>
                <Col span={12}>
                  <InformationL icon={<MailFilled />} value={appointment.location.secondaryEmail ?? "-"} />
                  <InformationL
                    icon={<PhoneFilled />}
                    value={
                      appointment.location.mobilePhoneNumber ? <PhoneNumber>{appointment.location.mobilePhoneNumber}</PhoneNumber> : "-"
                    }
                  />
                </Col>
              </Row>
              {appointment.status !== "STATUS_CANCELLED" ? (
                <Alert
                  type="info"
                  message={
                    <Steps current={STATUS_STEPS_CURRENT[appointment.status]} size="small" style={{ margin: "4px 0" }}>
                      <Steps.Step
                        title="Aangevraagd"
                        icon={<QuestionCircleFilled />}
                        description={appointment.requester.id !== "1" ? `door ${appointment.requester.username}` : undefined}
                      />
                      <Steps.Step title="Ingeroosterd" icon={<ClockCircleFilled />} />
                      <Steps.Step title="Bevestigd" icon={<CheckCircleFilled />} />
                    </Steps>
                  }
                  style={{ backgroundColor: "transparent", margin: "2px 0 8px 0" }}
                />
              ) : (
                <Alert type="warning" message="Afspraak is geannuleerd" style={{ margin: "2px 0 8px 0" }} showIcon />
              )}
              {appointment.relation.responsibleEmployee !== null && (
                <Alert
                  type="info"
                  message={`${appointment.relation.responsibleEmployee.username} is de verantwoordelijke salesmedewerker`}
                  style={{ backgroundColor: "transparent", margin: "2px 0 8px 0" }}
                  icon={<CustomerServiceOutlined />}
                  showIcon
                />
              )}
              {appointment.confirmedByCustomer !== null && (
                <Alert
                  type="info"
                  message={`Afspraak is bevestigd door ${appointment.confirmedByCustomer ? "klant" : "medewerker"}`}
                  style={{ backgroundColor: "transparent", margin: "2px 0 8px 0" }}
                  icon={<CheckCircleOutlined />}
                  showIcon
                />
              )}
              {appointment.onSiteContactPerson !== null && (
                <Alert
                  type="info"
                  message={
                    <>
                      <Typography.Text>Klant heeft afwijkend contactpersoon opgegeven voor buitendienst</Typography.Text>
                      <Typography.Text italic style={{ display: "block" }}>
                        Naam: {appointment.onSiteContactPerson}, telefoonnummer: {appointment.onSiteContactPersonPhoneNumber}
                      </Typography.Text>
                    </>
                  }
                  style={{ backgroundColor: "transparent", margin: "2px 0 8px 0" }}
                  icon={<PhoneOutlined />}
                  showIcon
                />
              )}
              <Row justify="space-between" gutter={24}>
                <Col span={12}>
                  <Form.Item label="Afspraak-type" name="appointmentTypeId" rules={[{ required: true }]}>
                    <AppointmentTypePicker
                      popupMatchSelectWidth={false}
                      preloadWithAppointmentType={{
                        ...appointment.appointmentType,
                        parentCategoryId: appointment.appointmentType.category.id,
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Monteur"
                    name="employeeId"
                    rules={[{ required: appointment.status === "STATUS_CONFIRMED" || appointment.status === "STATUS_SCHEDULED" }]}
                  >
                    <EmployeePicker groupId="GROUP_SERVICE_EMPLOYEES" />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between" gutter={24}>
                <Col span={12}>
                  <Form.Item
                    label="Datum"
                    name="date"
                    rules={[{ required: appointment.status === "STATUS_CONFIRMED" || appointment.status === "STATUS_SCHEDULED" }]}
                  >
                    <DatePicker format="LL" style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Tijd" extra={`Schatting: ${formatDuration(appointment.durationEstimate)}`}>
                    <Form.Item
                      name="startTime"
                      style={{ display: "inline-block", width: "calc(50% - 16px)" }}
                      rules={[{ required: appointment.status === "STATUS_CONFIRMED" || appointment.status === "STATUS_SCHEDULED" }]}
                    >
                      <TimePicker format="HH:mm" minuteStep={5} placeholder="" />
                    </Form.Item>
                    <span style={{ display: "inline-block", width: "32px", lineHeight: "32px", textAlign: "center" }}>-</span>
                    <Form.Item
                      className="appointment-end-time"
                      name="endTime"
                      style={{ display: "inline-block", width: "calc(50% - 16px)" }}
                      rules={[{ required: appointment.status === "STATUS_CONFIRMED" || appointment.status === "STATUS_SCHEDULED" }]}
                    >
                      <TimePicker format="HH:mm" minuteStep={5} placeholder="" />
                    </Form.Item>
                  </Form.Item>
                </Col>
              </Row>
              {appointment.displayTimeWindow && (
                <AppointmentTimeWindowSidebar
                  displayTimeWindow={appointment.displayTimeWindow}
                  timeWindowStart={appointment.timeWindowStart}
                  timeWindowEnd={appointment.timeWindowEnd}
                />
              )}
              <Space direction="vertical" size="small">
                <Form.Item
                  valuePropName="initialValue"
                  label="Planning notities (afspraak)"
                  name="plannerComment"
                  style={{ marginBottom: 0 }}
                >
                  <MarkdownTextInput />
                </Form.Item>

                <AppointmentRemindMeSidebar appointmentId={appointmentId} />
              </Space>
              <Space direction="vertical" size="small">
                <Form.Item valuePropName="initialValue" label="Buitendienst notities" name="onSiteComment" style={{ marginBottom: 0 }}>
                  <MarkdownTextInput />
                </Form.Item>
                <Form.Item name="attachments" valuePropName="fileList">
                  <AttachmentInput />
                </Form.Item>
              </Space>
              <Form.Item valuePropName="initialValue" label="Facturatie notities (afspraak)" name="invoiceComment">
                <MarkdownTextInput />
              </Form.Item>
            </Form>
          </Col>
          <Col span={12}>
            {appointment.relation.planningComment && (
              <OptionalInformation label="Planning notities (relatie)" value={appointment.relation.planningComment} />
            )}
            {appointment.relation.onSiteComment && (
              <OptionalInformation label="Buitendienst notities (relatie)" value={appointment.relation.onSiteComment} />
            )}
            {appointment.relation.invoiceComment && (
              <OptionalInformation label="Facturatie notities (relatie)" value={appointment.relation.invoiceComment} />
            )}
            {appointment.location.planningComment && (
              <OptionalInformation label="Planning notities (locatie)" value={appointment.location.planningComment} />
            )}
            {appointment.location.onSiteComment && (
              <OptionalInformation label="Buitendienst notities (locatie)" value={appointment.location.onSiteComment} />
            )}
            {appointment.location.invoiceComment && (
              <OptionalInformation label="Facturatie notities (locatie)" value={appointment.location.invoiceComment} />
            )}
            <Divider />
            <ProductsSummaryCard appointment={appointment} />
          </Col>
        </Row>
      )}
    </Modal>
  );
}

const STATUS_STEPS_CURRENT = {
  STATUS_REQUESTED: 0,
  STATUS_SCHEDULED: 1,
  STATUS_CONFIRMED: 2,
};

const AppointmentQuery = gql`
  query AppointmentQuery($appointmentId: ID!) {
    appointment(id: $appointmentId) {
      id
      appointmentType {
        id
        name
        category {
          id
        }
        deletedAt
        hasEmailTemplate
      }
      relation {
        id
        name
        place
        afasCode
        planningComment
        onSiteComment
        invoiceComment
        responsibleEmployee {
          id
          username
        }
        dealer {
          id
          afasCode
          name
        }
      }
      location {
        id
        name
        contactPerson
        primaryEmail
        secondaryEmail
        phoneNumber
        mobilePhoneNumber
        planningComment
        onSiteComment
        invoiceComment
        address {
          street
          city
          postalCode
          country
        }
      }
      employee {
        id
        username
      }
      requester {
        id
        username
      }
      onSiteContactPerson
      onSiteContactPersonPhoneNumber
      inviteSentOn
      prospectiveDate
      startTime
      endTime
      durationEstimate
      displayTimeWindow
      timeWindowStart
      timeWindowEnd
      confirmedByCustomer
      onSiteComment
      plannerComment
      invoiceComment
      status
      consumableGoods {
        id
        productType {
          id
          description
        }
        amount
      }
      attachments {
        id
        file {
          id
          name
        }
      }
    }
  }
`;

const CancelAppointmentMutation = gql`
  mutation ($appointmentId: ID!) {
    cancelAppointment(input: { id: $appointmentId }) {
      appointment {
        id
        status
        startTime
        endTime
        employee {
          id
          username
        }
        confirmedByCustomer
        inviteSentOn
        displayTimeWindow
        timeWindowStart
        timeWindowEnd
      }
    }
  }
`;

const ScheduleAppointmentMutation = gql`
  mutation (
    $appointmentId: ID!
    $appointmentTypeId: ID
    $startTime: DateTime!
    $endTime: DateTime!
    $employeeId: ID!
    $onSiteComment: String
    $plannerComment: String
    $invoiceComment: String
    $attachmentIds: [ID!]
  ) {
    scheduleAppointment(
      input: {
        id: $appointmentId
        appointmentTypeId: $appointmentTypeId
        startTime: $startTime
        endTime: $endTime
        employeeId: $employeeId
        onSiteComment: $onSiteComment
        plannerComment: $plannerComment
        invoiceComment: $invoiceComment
        attachmentIds: $attachmentIds
      }
    ) {
      appointment {
        id
        appointmentType {
          id
          name
          category {
            id
          }
          hasEmailTemplate
        }
        status
        inviteSentOn
        displayTimeWindow
        timeWindowStart
        timeWindowEnd
        confirmedByCustomer
        startTime
        endTime
        onSiteComment
        plannerComment
        invoiceComment
        employee {
          id
          username
        }
        attachments {
          id
          file {
            id
            name
          }
        }
      }
    }
  }
`;

const UnscheduleAppointmentMutation = gql`
  mutation ($appointmentId: ID!) {
    unscheduleAppointment(input: { id: $appointmentId }) {
      appointment {
        id
        inviteSentOn
        displayTimeWindow
        timeWindowStart
        timeWindowEnd
        confirmedByCustomer
        startTime
        endTime
        employee {
          id
          username
        }
        status
      }
    }
  }
`;

const ConfirmAppointmentMutation = gql`
  mutation ($appointmentId: ID!) {
    confirmAppointment(input: { id: $appointmentId }) {
      appointment {
        id
        status
        confirmedByCustomer
      }
    }
  }
`;

const UpdateAppointmentMutation = gql`
  mutation (
    $appointmentId: ID!
    $appointmentTypeId: ID
    $startTime: DateTime
    $endTime: DateTime
    $onSiteComment: String
    $plannerComment: String
    $invoiceComment: String
    $attachmentIds: [ID!]
    $employeeId: ID
  ) {
    updateAppointment(
      input: {
        appointmentId: $appointmentId
        appointmentTypeId: $appointmentTypeId
        startTime: $startTime
        endTime: $endTime
        onSiteComment: $onSiteComment
        plannerComment: $plannerComment
        invoiceComment: $invoiceComment
        attachmentIds: $attachmentIds
        employeeId: $employeeId
        respectTimeWindowIfUsing: false
      }
    ) {
      appointment {
        id
        appointmentType {
          id
          name
          category {
            id
            name
          }
          hasEmailTemplate
        }
        employee {
          id
          username
        }
        inviteSentOn
        displayTimeWindow
        timeWindowStart
        timeWindowEnd
        confirmedByCustomer
        prospectiveDate
        startTime
        endTime
        onSiteComment
        plannerComment
        invoiceComment
        status
        attachments {
          id
          file {
            id
            name
          }
        }
      }
    }
  }
`;
